
.menuOPVProcessos {
  display: grid;
  justify-content: end !important;
  flex: auto;
  margin-right: 0.5rem;
}

.dommus-toggle-height-fixed-d {
  height: calc(100vh - 250px);
  overflow-y: auto;
  padding: 5px 0px !important;
}

#DropOPVProcessos {
  padding: unset;
  color: #353252;
  background-color: unset !important;
  border: unset !important;
  //transform: scale(1.5);
  height: 22px;
  margin-top: -8px;
}
// .opcaoMenu{
//   &:focus {
//     color: white;
//     text-decoration: none;
//     background-color:var(--dommus-color-secondary);
//   }
//   &:hover {
//     color: white;
//     text-decoration: none;
//     background-color: var(--dommus-color-secondary);
//   }
// }

#DropOPVProcessos::after {
  display: none;
}

.menuClienteHistoricoTarefas {
  display: contents;
  position: relative;
  height: var(--header-height);
  text-align: center;
  padding-bottom: 2rem;
  justify-content: center;
  .nav-tabs {
    justify-content: center;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: unset;
    border-radius: 0px;
  }
  .nav-link {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  a:hover {
    color: var(--dommus-color-secondary);
  }
  .nav-link.active {
    color: var(--dommus-color-secondary) !important;
    border-color: var(--dommus-color-secondary);
    border-bottom: solid;
  }
  .menu_direita a {
    color: #b8b8b8;
    border-bottom: solid;
    border-radius: unset;
  }
  .nav {
    flex-wrap: unset !important;
  }
  .card-body {
    padding: 1.25rem !important;
  }
}
/* dadosDetalhes */
#dadosDetalhes {
  display: contents;
}
.conteudo {
  overflow-y: auto;
  height: 87%;
  overflow-x: hidden;
}
.btnAcoes {
  border: none;
  background-color: var(--dommus-color-secondary);
  display: flex;
  width: -webkit-fill-available;
  margin: 0.5rem;
  justify-content: center;
}
#right .icon {
  right: 1.4rem;
}

#right .sidebar {
  background: var(--bg-right);
  margin: auto;
}

@media (orientation: portrait) {
  #right .sidebar.open {
    transform: translateX(-100%);
  }
}

#dadosDetalhes .input-group-append {
  align-self: center;
}

#dadosDetalhes .fotoEnvolvido svg {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 50px;
}

#dadosDetalhes .fotoEnvolvido {
  font-size: 95px;
  line-height: 1;
  display: flex;
  justify-content: center;
}
#dadosDetalhes .dadosPessoaisCliente {
  margin-bottom: 0.5rem;
}

#dadosDetalhes .accordion {
  padding-top: 1rem;
}

.fotoPerfilEnvolvido {
  width: 100px;
}
