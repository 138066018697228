$border: 1px #cecece solid;

.formCC {
  height: unset !important;
}
.formCCO {
  height: unset !important;
}
.email {
background-color: #ffffff;
border: $border;
border-radius: 4px;
// padding: 5px;
&:not(:last-child) {
    margin-bottom: 10px;
}
.header {
    border-bottom: $border;
    font-weight: 500;
    font-size: 0.9em;
    .dadosCopiaCortesia {
    .emailCopiaCortesia {
        color: #868aa8;
    }
    }
    .dadosEnvio {
    text-align: left;
    background-color: #ececec;
    padding: 0.5rem;
    }
    .data {
    font-size: 0.8em;
    text-align: right;
    }
    .dadosAssunto {
    text-align: left;
    .iconeEnviadoRecebido{
        font-size: 1rem;
        margin-right: 0.25rem;
    }
    }
    .subject {
    font-size: 1em;
    font-weight: 700;
    text-align: center;
    width: 100%;
    margin: 5px 0;
    }
    .header-body {
    display: flex;
    flex-direction: row;
    .from-to {
        flex-grow: 1;
    }
    }
}
.body {
    margin-top: 10px;
    padding-left: 1rem;
    ul{
    margin-left: 2rem;
    }
}
}
