* {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body, #root {
    min-height: 100%;
}

body {
    font-family: 'Poppins', Arial, Helvetica, sans-serif !important;
    font-size: 14px;
    background-color: #f2f3f8 !important;
}

.wrapper {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    justify-content: space-between;
}

.content {
    width: calc(100% - 50px);
    padding: 15px;
    margin-top: 75px;
    overflow-x: hidden;
}

/* Variaveis */

:root {
    /* Cores */
    /* --dommus-color:#0a71b2; */
    --dommus-color: #183b64;
    /* --dommus-color-2:#f19d1f; */
    --dommus-color-2: #f7941d;
    /* --dommus-color-secondary:#64B5F6; */
    --dommus-color-secondary-light: #3074c3;
    --dommus-color-secondary: #265d9c;
    --dommus-color-hover: #dee2e6;
    --dommus-color-cinza: #868aa8;
    --dommus-color-green: #4CAF50;
    --dommus-color-red: #f44336;
    --dommus-color-dark: #353252;
    --dommus-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --dommus-b-radius: 5px;
    --dommus-overflow-width: 6px;
    --dommus-overflow-height: 6px;


    /*  */
    --smooth: 200ms ease-in-out;
}

*::-webkit-scrollbar-track {
    background-color: #ddd;
}

*::-webkit-scrollbar {
    width: var(--dommus-overflow-width);
    height: var(--dommus-overflow-height);
    background-color: #ddd;
    border-radius: 10px;
}

body::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--dommus-color-secondary);
    border-radius: 10px;
}

.form-control .form-control-color {
    padding: .375rem 14rem !important;
}

.form-group .react-datepicker-wrapper {
    width: 100%;
}

.icon-em-construcao {
    cursor: not-allowed;
    color: var(--dommus-color-cinza);
    opacity: 40%;
}

.buttonNovaOportunidade.dropup {
    background: none;
}

.buttonNovaOportunidade.dropup .dropdown-toggle::after {
    content: none;
}

.buttonNovaOportunidade.dropup .btn {
    background-color: transparent;
}

.item-barra {
    font-size: 17px;
}

.buttonNovaOportunidade .btn.btn-primary {
    background-color: #0a71b2;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    bottom: 30px;
    padding: 15px;
    font-size: 35px;
    border-radius: 100%;
    cursor: pointer;
}

.acoes-tabela .dropdown-toggle::after{
    display: none !important;
}

.dropdown-menu{
    padding: 0px !important;
}

.dropdown-item{
    padding: 1rem !important;
    font-size: .9rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.108) !important;
}
.dropdown-item:last-child{
    border-bottom: none !important;
}

.dropdown-item svg{
    margin-right: 10px;
}

.tabs-dommus a.active {
    background-color: var(--dommus-color)!important;
    color: #fff!important;
    border-color: var(--dommus-color) !important;
}
.tabs-dommus a.active:hover {
    border-color: var(--dommus-color) !important;
}

.tabs-dommus a {
    color: var(--dommus-color-cinza);
    font-weight: 500;
    font-size: 0.8rem;
}

.tabs-dommus a:hover {
    border: 1px solid gainsboro;
    border-bottom: none;
    color: var(--dommus-color-cinza);
}

.tab-dommus {
    margin-top: 10px;
}

/* .iti__flag {background-image: url("./img/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("./img/flags@2x.png");}
} */

fieldset {
    border: 1px solid #ddd!important;
    padding: 4px!important;
    border-radius: 10px!important;
}

legend {
    width: auto!important;
    font-size: 0.9em!important;
}

.inputLabel {
    color: #868aa8 !important;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 400 !important;
}

.form-control:focus{
    box-shadow: 0 2px 0 0 #183b64!important;
    border-color: #183b64!important;
}