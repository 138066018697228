$cor-recebido: #999;
$cor-enviado: var(--dommus-color-secondary);
$cor-texto: #ffff;

.whatsapp-container {
  background: url(https://bot.dommus.com.br/whatsapp/bg-whatsapp.jpg);
  background-position: center;
  background-size: 75% 50%;;
  overflow-y: auto;
  overflow-x: hidden;
  height: 95.5%;
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem 0.5rem 0rem;
  .mensagem-item {
    border-radius: 10px;
    color: $cor-texto;
    padding: 10px;
    margin: 5px 0px;
    &.enviada {
      // display: flex;
      background-color: #d9fdd3!important;
      margin-left: auto;
      max-width: 50%;
      width: fit-content;
      // .data {
      //     text-align: right;
      // }
    }
    &.recebida {
      // display: flex;
      background-color: #fff!important;
      float: left;
      max-width: 50%;
      width: fit-content;
    }
  }
  .data {
    color: $cor-recebido;
    text-align: left;
    font-size: 0.75em;
    display: block;
    width: 98%;
    font-weight: 500;
    &.enviada {
      text-align: right;
    }
    &.recebida {
      margin-left: 0.3rem;
    }
  }
}

.separador {
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  color: grey;
  &:after,
  &:before {
    background-color: #bbbbbb;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 20%;
    margin: 0 20px;
  }
}

@media screen and (min-width: 1024px) {
  .whatsapp-container {
    margin: -1rem 0rem 0rem 0rem;
  }
}

@media(max-width:767px){
  .whatsapp-container{
    max-height: -webkit-calc(100% - 8rem);
    max-height: -moz-calc(100% - 8rem);
    max-height: calc(100% - 8rem);
    position: relative;
    bottom: 1.5rem;
  }
}
