.disparo-info-section{
    display: flex;
    flex-direction: column;
    
}
.disparo-info-section .label{
    color: #666;
    font-size: 0.8rem;
}

.disparo-info-section .value{
    font-size: 1rem;
    text-transform: capitalize;
}
.fixed-height{
    max-height: 45vh;
    overflow-y: auto;
}

.alert-loading{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}
.alert-loading .scroll-carregando-msg{
    font-weight: 600;
    font-size: 1.1rem;
}

.disparo-info-section .badge-arquivo{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;    
    width: 100%;
    background-color: var(--dommus-color-cinza);
    color: white;
    font-weight: 700;
    border-radius: 10px;
    padding: 2px 10px;
}
.disparo-info-section .badge-arquivo:hover{
    outline: none;
    text-decoration: none;
}

.disparo-info-section .badge-arquivo span{
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap ;
}
