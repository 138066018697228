.card-outro-chamado {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    border: 1px solid gainsboro;
    cursor: pointer;
}

.card-outro-chamado .card-infos {
    padding: 8px;
    font-size: 10px;
}

.card-infos .row {
    width: 100%;
    margin: 0;
    margin-bottom: 2px;
}

.midia-outro-chamado .registro-col {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 100%;
}

.midia-outro-chamado svg {
    padding: 10px;
}

.card-infos .info-atendimento .contato-origem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3px;
    background-color: #ddd;
    color: #666;
    border-radius: 5px;
    padding: 2px 5px;
    width: fit-content;
}

.card-infos .info-atendimento .contato-origem span{
    color: #666;
    font-size: 10px;
}

.card-infos .info-atendimento .situacao {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 2px 4px;
    color: white;
    width: fit-content;
}

.card-infos .info-atendimento {
    padding: 0;
    align-self: center;
}

.info-ellipse {
    max-width: 10rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-infos .dados-atendimento {
    display: flex;
    padding: 0;
    justify-content: end;
    align-self: center;
}

.item-icone {
    display: flex;
    flex-direction: row;
    gap: 2px;
    justify-items: center;
}
