#img-usuario {
    border: 5px solid;
}

.label-descricao-campo-dashboard {
    font-size: 6pt;
}

.bg-coluna-3-dashboard {
    background-color: #b7c2cc!important;
}

.cursor-help {
    cursor: help!important;
}

.font-16-px {
    font-size: 16px!important;
}

.cursor-help:hover .font-16-px-hover {
    font-size: 16px!important;
}