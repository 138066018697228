.tabelaMidias{
  .midiaCard {
    display: contents;
    .midiaListaTemplate {
      width: 40px;
      display: grid;
      text-align: center;
      padding: 0 0 0.25rem 0.25rem;
    }
    label {
      position: relative;
      user-select: none;
    }

  }
  .midiaCorpo {
    padding: 0;
    font-size: 1.5rem;
  }

  .midiaCard input[type="checkbox"] {
    display: none;
  }
}
.opcoesTemplate {
  display: flex;
  font-size: larger;
  justify-content: space-between;
  padding: 0.5rem;
  color: #353252;
  .buttonEditarAnotacao {
    cursor: pointer;
    z-index: 1;
  }
}

.buttonDispararEmMassa{
  margin-right: 1rem;
}

.tox-tinymce {
  height: 70vh!important;
}