.midiaAtendimentoArquivado{
    .midiaCard {
      display: contents;
      .midiaListaTemplate {
        width: 40px;
        display: grid;
        text-align: center;
        padding: 0 0 0.25rem 0.25rem;
      }
      label {
        position: relative;
        user-select: none;
      }
      
    }
    .midiaCorpo {
      padding: 0;
      font-size: 1.5rem;
    }
  
    .midiaCard input[type="checkbox"] {
      display: none;
    }
  }

.canalTabelaAtendimentoArquivados, .recuperarTabelaAtendimentoArquivados{
    width: 1rem;
}
.recuperarCol{
    display: flex;
    justify-content: center;
    align-items: center;
}
.desarquivarTabelaIcone{
    cursor: pointer;
}