.headerBusca{
  margin-bottom: 1rem;
}
.listaProcessosEncontrados {
  overflow-y: auto;
  min-height: 15vh;
  overflow-x: hidden;
}
.conteudoListado {  
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  min-height: 50%;
  height: auto;
  max-height: 70%;
  overflow-x: hidden;
}
.proponenteCPFTelefone{
  font-size: 12px;
  color: var(--dommus-color-cinza);
}
.proponenteEmail{
  font-size: 12px;
  color: var(--dommus-color-cinza);
}
.procurarOpvProcessos {
  background-color: var(--dommus-color-secondary);
  border: none;
}
.btnEnviarGestaoLeads {
  margin-top:1rem;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  background-color: var(--dommus-color-secondary);
  border: none;
}
.dommus-toggle-height-fixed-busca-processo{
  height: 69.6vh;
  overflow-y: hidden;
  &.center-container-toggle {
    height: 75vh;
    overflow-y: hidden;
    padding: 1rem 0 0 0;
  }
}

.formFooter{
  margin-top: 2rem;
  border-top: 1px solid #cccc;
  border-bottom: 0;
  padding-top: 10px;
}

#buscaProcessos {
  min-height: 70vh;
  margin: 0;
  display: contents;
  padding-bottom: 0.5rem;
  position: relative;

  .header {
    display: contents;
    position: relative;
    height: var(--header-height);
    text-align: center;
    padding-bottom: 1rem;
    justify-content: center;
  }
  .processoListItem {     
    .proponenteNome {
      font-size: 0.9rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:hover {
      background-color: var(--dommus-color-secondary);
      color: white;
    }
  }
  .totalEncontrado {
    position: absolute;
    width: 100%;
    background-color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    padding-top: 5px;
    padding-bottom: 5px;
    bottom: 0px;
    right: 15px;
  }
  .btnAlterar{
    background-color: var(--dommus-color);
    border:none;
  }
  
  .btnBuscar{
    background-color: var(--dommus-color-2);
    border:none;    
  }
}


