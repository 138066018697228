.fullscreen-modal{
    z-index: 1040;    
    min-height: 100vh;
    min-width: 100vw;
    padding: 0px !important;
    margin: 0px;    
}

.fullscreen-modal .modal-dialog{
    max-width: 100vw;
    max-height: 100vh;
    margin: 0px;
}

.fullscreen-modal .modal-dialog .modal-content{
    max-height: 100vh;
    height: 100vh;
    overflow-y: hidden;
}

.fullscreen-modal .conteudo{
    overflow: auto;
}

.conteudo .nav-tabs {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 1rem;
}

.conteudo .nav-tabs .nav-link.active {
    color: #fff;
    background-color: var(--dommus-color);
    border-color: var(--dommus-color);
}

.conteudo .nav-tabs .nav-link {
    white-space: nowrap;
    color: var(--dommus-color-cinza);
    font-size: 14px;
}
.modal-header{
    align-items: center;

}
.modal-title h2{
    font-size: 14px;
    font-weight: 700;
}


@media (min-width:992px) {
    .modal-lg {
        max-width: 1200px;
    }
    
}

@media (max-width: 900px){
    .fullscreen-modal .conteudo{
        padding: 0px !important;
    }
}