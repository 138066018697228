.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  overflow: auto;
}

.containerInfos {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icone {
  width: 50px;
}

.botaoResgate {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  background-color: var(--dommus-color);
  color: #fff;
}

.botaoResgate:hover {
  border: 1px solid var(--dommus-color);
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  color: var(--dommus-color);
  font-weight: 500;
  transition: 0.2s;
}

.botaoLimparBacia {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  background-color: var(--dommus-color-red);
  color: #fff;
}

.botaoLimparBacia:hover {
  border: 1px solid var(--dommus-color-red);
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  color: var(--dommus-color-red);
  font-weight: 500;
  transition: 0.2s;
}

.listaBaciaOpen {
  position: absolute;
  right: 0;
  top: 75px;
  background-color: #fff;
  width: 40%;
  height: 100%;
  z-index: 1000;
  transition: 0.4s;
}

.borrarFundo {
  width: 300%;
  height: 100%;
  position: absolute;
  right: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.headerLista {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  font-weight: 500;
  align-items: center;
}

.listaBaciaClose {
  display: none;
}

.iconeCard {
  height: 50px;
  width: 50px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.listaBacia .registros-container .registro {
  pointer-events: none !important;
}
