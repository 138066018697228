.dadosVinculacao{    
    .messmensagemage{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    label {
        font-size: 12px;
    }        
    .midia-section{
      .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        padding: 5px;
      }
      svg{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 100%;
      }
    }
    .email-section{
        text-overflow: ellipsis;
    }
}
