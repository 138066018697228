.borda-tab {
    border-top: none!important;
    border: 1px solid #dee2e6;
    border-top-left-radius: 0px!important;
}
.linha-tab {
    padding-left: .96rem!important;
}

.tabela-disparo{
    min-height: 50vh;
}