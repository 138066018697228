.whatsapp-template {
    border-bottom: 1px solid #dddddd;
    display: grid;
    height: 28%;
    overflow-y: auto;
    flex-direction: column;
    .templateCard input[type="radio"] {
      display: none;
    }

    label .templateCard {
      transition-duration: 0.2s;
      transform-origin: 50% 50%;
    }

    .templateCard {
      display: contents;
      .nomeTemplate{
        margin-left: 1rem;
        font-weight: bolder;
        font-size: 14px;
      }
      .template {
        display: grid;
        background-color: var(--dommus-color-dark);
        color: white;
        border-radius: 0.25rem;
        padding: 0.5rem 2rem;
        font-size: 1rem;
        margin: 0rem 0rem 1rem 0rem;
      }
      label {
        position: relative;
        cursor: pointer;
        user-select: none;
        margin-left: -1.5rem;
      }
      label::before {
        font-size: x-large;
        background-color: white;
        color: white;
        //   display: block;
        border-radius: 50%;
        position: absolute;
        top: -1.2rem;
        left: -0.8rem;
        text-align: center;
        line-height: 85%;
        transition-duration: 0.4s;
        transform: scale(0);
      }
    }
    .templateCard :checked + label {
      border-color: #ddd;
    }

    .templateCard :checked + label::before {
      content: "✓";
      background-color: var(--dommus-color-green);
      transform: scale(1);
      z-index: 25;
    }

    .templateCard :checked + label .midiaCard {
      transform: scale(0.9);
      box-shadow: 0 0 5px var(--dommus-color-green);
      z-index: -1;
    }


    .filterForm{
      background: #eee;
      border-radius: 10px;
      padding: 10px 20px;
      margin: 0px 15px;

      .btn-group{
        display: flex;
        flex-direction: row;
        gap: 10px;

        .btnFilter{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          gap: 5px;
        }

      }


      label {
        color: #666;
      }
    }
  }

@media(max-width:767px) {
  .whatsapp-template{
    display: block;
    .filterForm{
      background: #eee;
      border-radius: 10px;
      padding: 0.5rem 1rem;
      margin: 0rem 0.5rem;
      .btn-group-pesquisa{
        margin-top: 0.5rem;
      }
    }
    .listaTemplates{
      margin-top: 0.5rem;
      height: 20rem;
      overflow-y: auto;
    }
    .footerEnviarMensagem{
      margin-top: 0.5rem;
      height: 10rem;
    }
  }



}
