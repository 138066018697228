.img_upload {
  max-width: -webkit-fill-available;
}

.cardArquivo {
  cursor: default;
  display: flex;
  justify-content: center;
}

.card_arquivo_body {
  height: auto;
  padding: 1rem;
}

.area_btn_anexar {
  display: flex;
  justify-content: flex-end;
}

.anexarImagem {
  margin: 1rem 0 0;
  background-color: var(--dommus-color-secondary);
  border: none;
}
