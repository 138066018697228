.corpoLigacaoRecebida{
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .fotoEnvolvido {
        padding-bottom: 1rem;
        font-size: 95px;
        line-height: 1;
        display: flex;
        justify-content: center;
      }
    }
    .statusChamada{
        display: flex;
        justify-content: center;
    }
    .botoesAcao{
        margin-top: 2rem;
        display: flex;
        justify-content: space-around;
        font-size: x-large;
        button {
          &:focus {
            outline: 1px dotted;
          }
          border-radius: 50% !important;
          padding: 15px;
          height: 70px;
          width: 70px;
          margin-bottom: 1rem;
          &.botaoAtender:hover {
            color: #4caf50;
            background-color: white;
            border: 3px solid #4caf50;
          }
          &.botaoAtender {
            color: #eee;
            background-color: #4caf50;
            border: 3px solid #4caf50;
          }
          &.botaoDesligar:hover{
            color: #f44336;
            background-color: white;
            border: 3px solid #f44336;
          }
          &.botaoDesligar {
            color: #eee;
            background-color: #f44336;
            border: 3px solid #f44336;
          }
          &.botaoRejeitar:hover {
            color: #F1673C;
            background-color: white;
            border: 3px solid #F1673C;
          }
          &.botaoRejeitar {
            color: #eee;
            background-color: #F1673C;
            border: 3px solid #F1673C;
          }
      }
    }
.stage{
    justify-content: center;
    display: flex;
}
.dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--dommus-color-secondary);
    color: var(--dommus-color-secondary);
    animation: dotElastic 1s infinite linear;
  }

  .dot-elastic::before, .dot-elastic::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--dommus-color-secondary);
    color: var(--dommus-color-secondary);
    animation: dotElasticBefore 1s infinite linear;
  }

  .dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--dommus-color-secondary);
    color: var(--dommus-color-secondary);
    animation: dotElasticAfter 1s infinite linear;
  }

  @keyframes dotElasticBefore {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dotElastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes dotElasticAfter {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
}
