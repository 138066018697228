.navsProponente {
  font-weight: bold;
  margin-top: -15px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  font-size: x-small;
  .nav-link {
    margin-left: 1rem;
    background-color: #868AA8;
    border-radius: 50%;
    padding: 0.2rem;
    width: 1rem;
    height: 1rem;
  }
  .nav-link.active {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #f7941d;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.3);
  }
}
.dadosPessoaiscliente {
  .dadosIniciais {
    margin: -10px 0px 0px 10px;
  }
  .telefonecliente {
    font-size: 12px;
  }
  .emailcliente {
    font-size: 12px;
  }
}
#dadosDetalhes .conteudo{
  font-size: 12px;
}
