.dzu-dropzone {
  border: 2px dashed var(--dommus-color) !important;
  border-radius: 5px;
  background: #fafafa;
  overflow: auto !important;
  min-height: 200px;
  padding: 1rem;
  margin-bottom: 20px;
  text-align: center;
}

.anexosEmail {
  border-radius: 1rem !important;
  background-color: #f7f7f7;
  padding: 1rem !important;
  margin: 1rem;
  flex: inherit !important;
  .card {
    border-left: 1;
  }
  .removerAnexo {
    cursor: pointer;
  }
  .corpoAnexo {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    height: 6rem;
  }
  .card_anexo_body {
    cursor: default;
    min-height: 1rem;
  }
  .img_upload {
    max-width: -webkit-fill-available;
    max-height: 6rem;
    height: 6rem;
  }
  .card_anexo_body {
    justify-content: center;
    margin: 0%;
    padding: 0%;
  }
  .anexarImagem {
    margin: 1rem 1rem 0 0;
    background-color: var(--dommus-color-secondary);
    border: none;
  }
}
