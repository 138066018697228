.imagemLogin {
  width: 50%;
  position: relative;
  height: 100vh;
}

.imagemFundoLogin {
  background: #000 url('../../assets/bh.jpg') no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  position: absolute;
  background-size: cover;
  background-position: center center;
}

.login {
  display: flex;
}

.login .container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login .logo-dommus {
  max-width: 450px;
  height: 97px;
  border-radius: 5px;
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .logo-dommus img {
  width: 251px;
  height: auto;
}

.login .content {
  max-width: 460px;
  background: #fff;
  margin-top: 50px;
  border-radius: 4px;
  padding: 30px;
}

.login .content>p {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.login .content form {
  display: flex;
  flex-direction: column;
}

.login .content form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 8px;
}

.login .content form label span {
  font-size: 12px;
  color: #999;
  font-weight: normal;
}

.login .content form input, .login .content form select {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
}

.login .content form textarea {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 80px;
  padding: 0 15px;
  font-size: 16px;
}

.login .content div.buttons {
  display: flex;
  justify-content: space-between;
}

.login .content div.buttons a {
  width: 48%;
}

.login .content button.btn {
  border: 0;
  border-radius: 2px;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.login .content button.btn-submit {
  background: var(--dommus-color-secondary);
  color: #fff;
  border: 1px solid #f7faff;
}

.login .content button.btn-secondary {
  background: #ccc;
  width: 100%;
  color: #fff;
}

.login .content button.btn:hover {
  opacity: 0.9;
}

.login .error {
  font-weight: bold;
  color: red;
  margin-bottom: 10px;
}

.logo-dommus.mobile {
  display: none;
}

@media (max-width:767px) {
  .imagemLogin {
    display: none;
  }
  .logo-dommus.mobile {
    display: flex;
  }
  .content {
    width: 100%;
    margin-top: 55px;
  }
  .login .container {
    background: #000 url('../../assets/bh.jpg') no-repeat;
    width: 100%;
    height: 100vh;
  }
}