.progress-bar{
    background: #e9ecef;
    height: 20px;
    display: inline-block;
 }

.progress-bar:hover{
    cursor: pointer;
}

 /* *{
    position: relative;
 } */
 
.progress .div-horas{
    box-sizing: border-box;
}

.horas-bar{
    display: flex;
    height: 1rem;
    /* overflow: hidden; */
    font-size: .55rem;
    border-radius: 0.25rem;
}

.div-horas{
    top: 0px; 
    width: 10%;
}

.div-ponteiro{
    top: 0px; 
    border-right: 2px solid #ff1100;
    width: 10%;
    height: 8px;
}