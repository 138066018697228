.historico .dotHistorico{
    background-color: #f7941d !important;
}

.historico{
    align-items: flex-end;
}

.historico .paper{
    padding: 6px 16px;
    box-shadow: unset;
}
.historico .item::before{
    content:unset;
}
.historico .labelHora{
    color: #868aa8;
    font-size: 12px;
}