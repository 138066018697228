.agendamento-visualizacao-section{
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 5px;
}

.dados-agendamento-section{
    padding: 5px 15px !important;
    padding-bottom: 10px !important;
}

.td-meio-comunicacao .icon{
    padding: 5px 8px;
    font-size: 1.15rem;
    border-radius: 5px;
    margin-left: 30%;
}

@media(max-width: 767px){
    .td-meio-comunicacao .icon{
        margin-left: 20%;
    }   
}