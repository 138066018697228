.registro-col{
  width: 60px;
}   
.novoTemplate {
  .editorMensagem {
    z-index: 0;
  }
  .buttonsTemplate {
    display: flex;
    justify-content: flex-end;

    .submitTemplate {
      background-color: #4caf50;
      border: none;
      margin-top: 1rem;
    }
    .fecharModal {
      background-color: #b1b1b1;
      margin-right: 10px;
    }
  }
  .midiaCard input[type="checkbox"] {
    display: none;
  }

  label .midiaCard {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  .midiaCard {
    display: contents;
    .midia {
      width: 70px;
      display: grid;
      text-align: center;
      margin: 0 0 0.5rem 0;
    }
    label {
      position: relative;
      cursor: pointer;
      user-select: none;
    }
    label::before {
      font-size: x-large;
      background-color: white;
      color: white;
      content: " ";
      //   display: block;
      border-radius: 50%;
      border: 1px solid var(--dommus-color-green);
      position: absolute;
      top: -5px;
      left: -5px;
      text-align: center;
      line-height: 85%;
      transition-duration: 0.4s;
      transform: scale(0);
    }
  }
  .midiaCorpo {
    padding: 0;
    font-size: 2rem;
  }
  .midiaCard :checked + label {
    border-color: #ddd;
  }

  .midiaCard :checked + label::before {
    content: "✓";
    background-color: var(--dommus-color-green);
    transform: scale(1);
    z-index: 25;
  }

  .midiaCard :checked + label .midiaCard {
    transform: scale(0.9);
    box-shadow: 0 0 5px var(--dommus-color-green);
    z-index: -1;
  }
}
