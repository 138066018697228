.card-chamado-section{
  display: flex;
  flex-direction: column;
  border: 1px solid gainsboro;
  padding: 5px;
  border-radius: 8px;

}

.card-chamado-section .dados-section{
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.card-chamado-section .dados-section .first-column{
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: start;
  flex-basis: 18%;
  border-right: 1px solid gainsboro;
  padding: 5px;
}

.card-chamado-section .dados-section .first-column .protocolo{
  font-weight: 700;
  color: var(--dommus-color-2);
  cursor: pointer;
}

.card-chamado-section .dados-section .first-column .protocolo:hover{
  color: var(--dommus-color);
}

.card-chamado-section .dados-section .first-column .data{
  font-size: 0.7rem;
  color: grey;
}


.card-chamado-section .dados-section .first-column .icon{
  display: flex;
  justify-content: center;
  width: fit-content;
  border-radius: 5px;
  padding: 5px;
  font-size: 1.5rem;
}


.card-chamado-section .dados-section .second-column{
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-basis: 80%;
  padding: 5px;
}

.card-chamado-section .dados-section .second-column .info-section{
  display: flex;
  flex-direction: column;
  gap: 5px;  
}

.card-chamado-section .dados-section .second-column .info-section label{
  font-size: 0.7rem;
  color: grey;
  margin-bottom: 0px;
}

.card-chamado-section .dados-section .second-column .info-section .label-responsaveis{
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-chamado-section .footer-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid gainsboro;
  padding: 3px;
  padding-top: 5px;
}

.card-chamado-section .footer-section .badge-status-section{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.card-chamado-section .footer-section .badge-status-section .badge-status{
  max-width: 120px;  
  padding: 2px 8px;
  border-radius:3px;
  font-weight: 600;
  font-size: 0.9rem; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 4px;
}

.card-chamado-section .footer-section .btn-desvincular-atendimento,
.card-chamado-section .footer-section .btn-vincular-atendimento{
  padding: 2px 10px;
  font-size: 0.9rem;
}

.card-chamado-section .footer-section .btn-vincular-atendimento{
  background-color: #265d9c;
}


.card-chamado-section .footer-section .btn-desvincular-atendimento{
  background-color: var(--dommus-color-red);
  border-color: var(--dommus-color-red);
}


