.grid-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    justify-items: space-between;
    align-items: stretch;
    align-content: start;
    margin-top: 10px;
}

.card-dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width:1040px) {
    .grid-cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        margin-top: 10px;
    }
}

.btn-mais-info-dashboard {
    background-color: var(--dommus-color-2)!important;
    padding: 5px 8px;
    border: 1px solid var(--dommus-color-2)!important;
}

.btn-mais-info-dashboard:hover {
    background-color: #d88116;
    padding: 5px 8px;
    border: 1px solid #d88116;
}

.btn-mais-info-dashboard:focus {
    box-shadow: 0 0 0 0.2rem #f0962788!important;
}