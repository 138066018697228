$cor-recebido: #b6b6b6;
$cor-enviado: var(--dommus-color-secondary);
$cor-texto: #ffff;

.enviarMensagem {
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 16px;
}

#dropEmoticon {
  position: absolute;
  bottom: 0;
  left: 2.3rem;
  font-size: 1.2rem;
  background-color: #ffff;
  border: 1px solid #dddddd;
  cursor: pointer;
  border-radius: 0px;
}
.inputMensages {
  .opcaoMenu {
    text-align: initial;
    font-size: 0.9rem;
    margin: 0;
    .opcaoAnotacoes {
      color: var(--dommus-color-secondary);
      .tituloAnotacoes {
        color: var(--dommus-color-cinza);
        margin-left: 1rem;
      }
    }
    &:hover {
      background-color: unset;
      .tituloAnotacoes {
        color: var(--dommus-color-secondary);
      }
    }
    .opcaoEnviarImagem {
      color: var(--dommus-color-2);
      .tituloEnviarImagem {
        color: var(--dommus-color-cinza);
        margin-left: 1rem;
      }
    }
    &:hover {
      background-color: unset;
      .tituloEnviarImagem {
        color: var(--dommus-color-2);
      }
    }
    .opcaoTemplate {
      color: var(--dommus-color-green);
      .tituloTemplate {
        color: var(--dommus-color-cinza);
        margin-left: 1rem;
      }
    }
    &:hover {
      background-color: unset;
      .tituloTemplate {
        color: var(--dommus-color-green);
      }
    }
  }

  #dropMenuOpcoes {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 19px;
    background-color: var(--dommus-color-secondary);
    border: 1px solid var(--dommus-color-secondary);
    cursor: pointer;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  #dropMenuOpcoes:hover {
    filter: brightness(0.8);
  }
  #dropMenuOpcoes:disabled {
    background-color: #b6b6b6;
    border-color: #b6b6b6;
    cursor: not-allowed;
  }
  #dropMenuOpcoes:focus {
    background-color: #09588a;
  }
  #dropMenuOpcoes::after {
    display: none;

  }
  // .dropdown-menu.show {
  //   padding: 0.5rem;
  // }
}
nav.emoji-categories {
  display: none !important;
}
.listaEmoticon {
  padding: 0px;
}
#dropEmoticon::after {
  display: none;
}

.inputMensages {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0px 5px;
  padding-top: 0.7rem;
  background-color: #eee;
  .input-group-prepend {
    margin-right: 0.3rem;
  }
  .enviarButton {
    position: absolute;
    bottom: 0; /* Ajuste o valor conforme necessário para definir a distância do rodapé */
    left: 97%; /* Alinha o botão no centro horizontalmente (ou use right: 50% para alinhar à direita) */
    transform: translateX(-50%);
    background-color: var(--dommus-color-secondary);
    border: 0;
    padding: 5px 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    min-height: 2.4rem;
    &:hover {
      border: 0;
      background-color: #086097;
    }
  }
  .inputMensagem{
    resize: none;
    margin-left: 4.3rem;
    margin-right: 2.6rem;
    padding-bottom: 0px;
    min-height: 2.4rem;
  }
}

.alert-section{
  margin: 5px;
}

.section-atalho{
  position: absolute;
  min-width: 77%;
  height: 15rem;
  background: white;
  color: black;
  top: -15rem;
  left: 80px;
  border-radius: 5px;
  transition: all 700ms;
  overflow: auto;

  .header-atalho{
      margin: 2px 0px;
      background: rgb(242, 240, 240);
      color: var(--dommus-color-cinza);
      font-size: 0.8rem;
      padding: 5px;
  }

  .itens-atalho{
      padding: 5px;
  }
  .item-atalho{
      display: block;
      padding: 10px 10px;
      margin-bottom: 2px;
      font-size: 0.9rem;
      font-weight: 500;
      color: #495057;
      border-radius: 2px;
      border: 1px solid #ecebeb;
      text-decoration: none;
      &:hover{
          background: #e5e5e5;
      }
      &:focus{
          background: #e5e5e5;
          scroll-margin-top: 40px;
      }
      .icon-item-atalho{
          font-size: 1.3rem;
          margin-right: 0.5rem;
      }
      .conteudo-mensagem-item{
        color: #999;
        width: 100%;
        text-align: justify;
        font-size: 11px;
        max-height: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
                line-clamp: 2;
        -webkit-box-orient: vertical;
      }
  }
}
@media(max-width:767px) {
  .inputMensages{
    position: fixed;
    bottom: 3.5rem;
    left: 1.5%;
    .inputMensagem{
      resize: none;
      margin-left: 4.3rem;
      margin-right: 2.6rem;
      padding-bottom: 0px;
      min-height: 2.4rem;
    }
    .enviarButton{
      left:93%
    }
  }
}
