.integracao_pagina
    .card-img{
        max-width:200px;
        max-height:50px;
        width: auto;
        height: auto;
        
    }
 
.integracao_pagina
    .integracao-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    }

.integracao_pagina
    .card{
        display: flex;
        text-align: center;
        cursor: auto;
    }
.integracao_pagina   
    .card-header{
        background-color: #fff;
        font-weight: bold;
    }
.integracao_pagina 
    .card-body{
        background-color: #fff;
    }
    .integracao_pagina 
    .card-title{
    text-align: center;
} 

.IntegrarD {
    pointer-events: none;
    opacity: 0.5;
    background-color: #CCC;
    border: none;
    color: white;
    text-align: center;
    font-size: 16px;
    border: #0069D9;
    opacity: 0.9;
    border-radius: 5px !important; 
    
  }
.Integrar{
    background-color: #0069D9;
    border: none;
    color: white;
    text-align: center;
    font-size: 16px;
    border: #0069D9;
    opacity: 0.9;
    border-radius: 5px !important; 
    
  }
.Integrar:focus{
    outline: none;
}
.Integrar:hover{
    color: white;
    text-align: center;
    font-size: 16px;
    opacity: 1;
  }
  .tituloPagina{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #fff;
    font-weight: 700;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
}
.kep-login-facebook {
    visibility: hidden;
    position: relative!important;
}
.kep-login-facebook:after {
    visibility: visible!important;
    position: absolute!important;
    top: 0!important;
    left: 14%!important;
    text-transform: capitalize!important;
    font-weight: lighter!important;
    content: "Login Via Facebook"!important;
    border-radius: 4px!important;
    padding: 15px!important;
    background-color: #3C5B9A;
}