body,
.content {
  overflow: auto;
  height: 100%;
  overflow: hidden;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dommus-toggle-height-fixed-atendimento {
  height: 75vh;
  overflow-y: auto;
  padding-left: 0.3rem !important;
  padding-top: 0rem !important;
  &.center-container-toggle {
    overflow-y: hidden;
    padding: 1rem 0 0 0;
  }
}

.dommus-toggle-sem-margem-bottom + div {
  margin-bottom: 0px!important;
}

.indicativoFiltro {
  background-color: #ff5c58;
  border-radius: 9px;
  height: 17px;
  width: 17px;
  position: absolute;
  right: 25px;
  top: 85px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: flex;
}

.indicativoFiltro2{
  background-color: #ff5c58;
  border-radius: 9px;
  height: 17px;
  width: 17px;
  position: absolute;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: flex;
  right: 145px;
  top: 85px;
}

.indicativoFiltro3{
  background-color: #ff5c58;
  border-radius: 9px;
  height: 17px;
  width: 17px;
  position: absolute;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  color: #fff;
  display: flex;
  right: 0.5rem;
  top: 5.5rem;
}

.dommus-toggle-height-fixed {
  height: 75vh;
  // overflow-y: auto;
  &.center-container-toggle {
    height: 75vh;
    overflow-y: visible!important;
    background-color: #eee!important;
    padding: 1rem 0 0 0;
  }
}

/* Mobile Navigation */
.dommus-mobile-navigation{
  background-color: #fff;
  padding: 0 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  /* display: none; */
  z-index: 99;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.1);

}
.dommus-mobile-navigation .navigation{
  height: 50px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.restringir-height-container{
  position: fixed;
  overflow: auto;
  max-height: -webkit-calc(100% - 8rem);
  max-height: -moz-calc(100% - 8rem);
  max-height: calc(100% - 8rem);
}

.badge-identifica-data-tela-inicial{
  font-size: 0.8rem;
  background-color: #fd7e14;
  line-height: 25px;
}

.titulo-ca {
  width: 40%!important;
}
.periodo {
  display: flex;
  justify-content: center;
}