.novoChamadoForm {
  padding: 15px;
}
.tituloNovoChamado {
  text-align: center;
  color: white;
  background-color: #F16529;
  padding: 0.8rem;
  border-radius: 0.5rem;
}
.btNovoCadastro {
  display: flex;
  justify-content: center;
  width: inherit;
  border-radius: 0.3rem;

  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;

  padding: 0.5rem;
  border: none;
  color: white;
  background-color: #f16529;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
// .botaoCadastroProcesso{
//     background: transparent; border-color: #ddd;
// }

// .botaoCadastroProcesso:active, .botaoCadastroProcesso:focus, .botaoCadastroProcesso:hover{
//     background: transparent; border-color: #ddd;
// }
