.conteudoFianceiro{
    display:contents;
    font-size: 10px;
    margin: 0;

    .tebelaFinanceiro{
        text-align: center;
        margin: 0;
        width: -webkit-fill-available;
    }
}
.cardTabela{
    padding:0 !important; 
}