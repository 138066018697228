.cabecalhoTabelaTelefone{
    width: 1%
}
.cabecalhoTabelaLabel{
    width: 7%
}
.cabecalhoTabelaOpcoes{
    width: 5%
}

.areaOpcoes{
    display: flex;
    justify-content: center;
}