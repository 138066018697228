 .header {
    display: contents;
    position: relative;
    text-align: center;
    padding-bottom: 2rem;
    justify-content: center;
  }
  #anotacoes .nav-tabs {
    justify-content: center;
  }
  #anotacoes .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: unset;
    border-radius: 0px;
  }
  #anotacoes .nav-link {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  #anotacoes a:hover {
    color: var(--dommus-color-secondary);
  }
  #anotacoes .nav-link.active {
    color: var(--dommus-color-secondary) !important;
    border-color: var(--dommus-color-secondary);
    border-bottom: solid;
  }
  #anotacoes .menu_anotacoes a {
    color: #b8b8b8;
    border-bottom: solid;
    border-radius: unset;
  }
  #anotacoes .anotacoesContainer {
    overflow-y: auto;
    min-height: 28vh;
    height: auto;
  }
  #anotacoes .atendimentosdoDiaContent {
    overflow-y: auto;
    height: auto;
    .tebelaAtendimentosdoDiaContent{
      text-align: center;
      margin-left: 1rem;
      width: -webkit-fill-available;
      margin-right: 1rem;
    }
  }
  #anotacoes .inputMensagem {
    padding: 0 0.5rem;
    margin-bottom: 2vh;
  }

  @media(max-width: 767px){
    #anotacoes {
      .inputMensages {
        position: relative !important;
        display: flex;
        padding: 0px 5px;
        padding-top: 0.7rem;
        background-color: #eee;
        bottom: 0;
        left:0;
        .input-group button {
          bottom: 0.813rem;
        }
      }
    }
    .anotacoesContainer{
      max-height: 20rem;
      overflow-y: auto;
    }
  }
