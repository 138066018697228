.bg-perfil {
  background-color: #3E6780 !important;
}
.navegation {
  margin-top: -2.5rem;
  font-size: 14px;
  font-weight: 700;
}
.font-size-16 {
  font-size: 25px;
}
#img-perfil > picture > img {
  width: 150px!important;
  height: 150px!important;
}
.tab-perfil {
  justify-content: center;
}
#tab-informacoes {
  border-bottom: 3px solid #f7941d;
}
#pag-perfil {
  overflow-x: hidden;
}
#conteudo-informacoes {
  height: 25rem;
}
#img-perfil {
  position: relative;;
}
#alterar-foto {
  display: none;
}
#img-perfil:hover #alterar-foto {
  display: block;
  position: absolute;
  border-radius: 50%;
  background-color: #555;
  color: #fff;
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 2rem;
  cursor: pointer;
  opacity: 0.8;
}