.barra-titulo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: inherit;

}

.barra-titulo .item-barra {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 0.935rem;
    font-weight: 350;
    border-radius: 5px;
    font-size: 0.8rem;
    line-height: 1.5;
    color: #212529;
    flex-grow: 1;
    margin: 0 0px 15px 10px;
    text-align: center;
    width: 35%;

}

.mobile-view-barra-titulo .barra-titulo .item-barra {
  margin:0px !important;
}

.barra-titulo .item-barra:nth-child(2) {
    padding: 0.625rem;
}

.barra-titulo .item-barra:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    border-radius: var(--dommus-b-radius);
    border: 1px solid #ddd;
    margin-left: 0;
    font-weight: 700;

    font-size: 0.9rem;
}

.barra-titulo .item-barra.btn {
    max-width: 50px;
    min-width: 50px;
    font-size: 14px;
}

.barra-titulo .item-barra.btn svg {
    cursor: pointer;
}

.barra-titulo .item-barra.btn svg:hover {
    color: #f7941d;
}


.barra-titulo .item-barra.btn:hover {
    background-color: #4caf50;
    transition: background-color 0.2s;
}

.barra-titulo .item-barra.btn:hover svg {
    color: #ffffff;
}

.mobileBarraExtra {
    display: none;
}


@media (max-width:767px) {
    .barra-titulo .item-barra {
        padding: 5px;
        font-weight: 700;
        border-radius: 5px;
        margin: 0 10px 10px 10px;
    }

    .btn {
        display: block;
    }

    .barra-titulo:nth-child(2) .item-barra:first-child {
        display: none;
    }

    .barra-titulo .item-barra:nth-child(2) {
        display: none;
    }

    /* .barra-titulo .item-barra:nth-child(3){
        display: flex;
    } */
    .barra-titulo:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        width: 100%;
    }

    .barra-titulo .item-barra:first-child {
        display: flex;
        justify-content: space-between;
        margin-left: 10px;
    }

    .barra-titulo .item-barra span {
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        width: 30%;
    }

    .mobileBarraExtra {
        display: flex;
    }
}
