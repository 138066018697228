.agendamento-dados-section{
    display: flex;
    flex-direction: column;
}

.agendamento-dados-section .label{
    font-size: 0.8rem;
    font-weight: 700;
    color: #888;
}

.agendamento-anexos-section{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.agendamento-anexos-section .badge-anexo{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;    
    gap: 5px;
    padding: 5px;
    background-color: var(--dommus-color-cinza);
    color: white;
    max-width: 250px;
    border-radius: 10px;
    
}
.agendamento-anexos-section .badge-anexo:hover{
    text-decoration: none;
}

.agendamento-anexos-section .badge-anexo span{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap ;
}
.agendamento-anexos-section .badge-anexo svg{
    margin-top: 3px;
}
