.dommus-toggle-height-fixed-Atendimento {
  padding: 15px 0px !important;
}

.contatoToggle {
  display: flex;
  justify-content: end;
  flex: auto;
  margin-right: 1rem;
  color: #868aa8;
  font-weight: normal;
  .contatoInfo {
    display: flex;
    align-items: center;
    gap: 5px;

    .icon-meio_comunicao{
      .registro-col{
        width: min-content;
        padding: 0px 5px;
        border-radius: 0.2rem;
      }
      .icon{
        padding-left: 0.25rem;
        padding-top: 0.05rem;
      }
    }

    .nome-contato{
      padding-left: 5px;
      max-width: 12rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .data-hora-contato{
      display: flex;
      gap: 5px;
      .dataContato {
        font-size: 0.7rem;
      }
    }

  }
}
.height-coluna-atendimento {
  height: 75.3vh!important;
}
.mobileTab{
  height: 30rem;
  overflow-y: auto;
}
