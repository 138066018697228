.icone-prioridade{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: min-content;
  font-size: 0.9rem;
}

.icone-prioridade svg:first-child{
  margin-bottom: -5px;
}

