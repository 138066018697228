.container-agendamento-form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.container-agendamento-form fieldset{
    padding: 0px 10px !important;    
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 80vh;
    max-height: 80vh;
}

.agendamentos-disparos-section{
    display: flex;
    flex-direction: column;    
    gap: 25px;
    margin: 10px 0px;
}

.disparo-rodape-status {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.disparo-rodape-status .status-disparo-section{
    display: flex;
    flex-direction: row;
    gap: 10px;    
}

.disparo-rodape-status .status-disparo-section .info-section{
    display: flex;
    flex-direction: row;
    border-radius: 5px;    
    align-items: center;
    font-size: 0.9rem;
}

.disparo-rodape-status .status-disparo-section .info-section .nome{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 2px 5px;
    color: white;
    font-weight: 600;
    text-transform: capitalize;
}

.disparo-rodape-status .status-disparo-section .info-section .valor{
    padding: 2px 5px;
    border-right: 1px solid var(--dommus-color-cinza);
    border-top: 1px solid var(--dommus-color-cinza);
    border-bottom: 1px solid var(--dommus-color-cinza);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.disparo-rodape-status .status-disparo-section .info-section .nome.concluido{
    background-color: var(--dommus-color-green);
    border: 1px solid var(--dommus-color-green);
}

.disparo-rodape-status .status-disparo-section .info-section .nome.pendente{
    background-color: var(--dommus-color-2);
    border: 1px solid var(--dommus-color-2);
}

.disparo-rodape-status .status-disparo-section .info-section .nome.cancelado{
    background-color: var(--dommus-color-red);
    border: 1px solid var(--dommus-color-red);
}

.disparo-rodape-status .status-disparo-section .info-section .nome.erro{
    background-color: var(--dommus-color-dark);
    border: 1px solid var(--dommus-color-dark);
}

.disparo-rodape-status .status-disparo-section .info-section .nome.total{
    background-color: var(--dommus-color-cinza);
    border: 1px solid var(--dommus-color-cinza);
}

.disparo-rodape-status .btn-cancelar-disparo{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    border-radius: 5px;
    background-color: var(--dommus-color-red);
    color: white;
    padding: 2px 10px;
    border: none;
    font-weight: 600;
}
