.selectDataAtendimento{
	display:flex;
	justify-content: flex-end;
	margin: -2.5rem 2rem 2.5rem 0rem;

}

.react-date-picker {
	z-index: 3;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	* {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		&:before {
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
		}
		&:after {
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
		}
	}
}
.react-date-picker--disabled {
	background-color: #f0f0f0;
	color: #6d6d6d;
}
.react-date-picker__wrapper {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	border: none;
	border-bottom: thin solid rgb(196, 196, 196);
}
.react-date-picker__inputGroup {
	min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
	flex-grow: 1;
	padding: 0 2px;
	box-sizing: content-box;

}
.react-date-picker__inputGroup__divider {
	padding: 1px 0;
	white-space: pre;
	color:#353252;
}
.react-date-picker__inputGroup__input {
	color:#353252;
	min-width: 0.54em;
	height: 100%;
	position: relative;
	padding: 0 1px;
	border: 0;
	background: none;
	font: inherit;
	box-sizing: content-box;
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&:invalid {
		background: rgba(255, 0, 0, 0.1);
	}
}
.react-date-picker__inputGroup__input--hasLeadingZero {
	margin-left: -0.54em;
	padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
	border: 0;
	background: transparent;
	padding: 4px 6px;
	&:enabled {
		cursor: pointer;
		&:hover {
			.react-date-picker__button__icon {
				stroke: #0078d7;
			}
		}
		&:focus {
			.react-date-picker__button__icon {
				stroke: #0078d7;
			}
		}
	}
	&:disabled {
		.react-date-picker__button__icon {
			stroke: #6d6d6d;
		}
	}
	svg {
		display: inherit;
	}
}
.react-date-picker__calendar {
	width: 350px;
	max-width: 100vw;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1;
	.react-calendar {
		border-width: thin;
	}
}
.react-date-picker__calendar--closed {
	display: none;
}
