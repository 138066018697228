.toast{
    position: absolute;
    bottom: 20px;
    left: 50px;
    max-width: 50rem;
    background: white;
    -webkit-box-shadow: 8px 8px 13px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 8px 8px 13px 0px rgba(0,0,0,0.2);
    box-shadow: 8px 8px 13px 0px rgba(0,0,0,0.2);
    transition: all 500ms;
    transform: translateX(-110%);
    -webkit-transform: translateX(-110%);
    z-index: 100;
    
    &.show{
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
    }


    .toast-header{
        // background-color: var(--dommus-color-2);
    }

    .toast-icon{
        margin-right: 10px;
        color: var(--dommus-color-2);
        font-size: 16px;
    }

    .toast-body{
        width: 100%;        
    }

    .toast-container{
        display: flex;
        width: 100%;
        // padding: 0px;
        flex-direction: column;
    }

    .toast-buttons{
        display: flex;
        width: 100%;
        justify-items: flex-start;
        margin-top: 10px;
        gap: 5px;        
        button{
            font-size: 11px;
            font-weight: 600;
            padding: 5px;            
        }
    }

}