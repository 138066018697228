$cor-recebido: #999;
$cor-enviado: var(--dommus-color-secondary);
$cor-texto: #ffff;

.ra-container {
  overflow-y: auto;
  height: 95%;
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem 0.5rem 0rem;
  margin: -1rem 0rem 0rem 0rem;
  .mensagem-item {
    border-radius: 10px;
    color: $cor-texto;
    padding: 10px;
    margin: 5px 0px;
    &.enviada {
      // display: flex;
      background-color: $cor-enviado;
      margin-left: auto;
      max-width: 50%;
      width: fit-content;
      // .data {
      //     text-align: right;
      // }
    }
    &.recebida {
      // display: flex;
      background-color: $cor-recebido;
      float: left;
      max-width: 50%;
      width: fit-content;
    }
  }
  .data {
    color: $cor-recebido;
    text-align: left;
    font-size: 0.6em;
    display: block;
    width: 98%;

    &.enviada {
      text-align: right;
      margin-left: -0.3rem;
    }
    &.recebida {
      margin-left: 0.3rem;
    }
  }
}

.separador {
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  color: grey;
  &:after,
  &:before {
    background-color: #bbbbbb;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 20%;
    margin: 0 20px;
  }
}
