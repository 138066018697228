.chamadas-perdidas {
  // background-color: #F7491D;
  // height: calc(100vh - 700px);
  padding: 0px;
  padding-right: 19px;
  padding-bottom: 1.3rem;
  bottom: 0px;
  width: 100%;
  overflow-y: hidden;
  z-index: 100;
  .dommus-toggle {
    background-color: #F7941D;
    color: #fff;
  }
}

.dommus-toggle-chamadas-perdidas {
  padding: 0px !important;
  background-color: #eee !important;
  height: calc(100vh - 250px);
}

.alert-tabela-chamadas-perdidas {
  margin-top: 1rem;
}

.title-chamadas-perdidas {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  .contador-chamadas-perdidas {
    background-color: #fc0602;
    color: #fff;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}