.areaAnotacao {
  border: 2px solid var(--dommus-color-secondary);
  display: flex;
  justify-content: space-between;
  margin: 20px;
  border-radius: 4px;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  color: #353252;Sobre
  .buttonEditarAnotacao {
    cursor: pointer;
    z-index: 1;
  }
}
.areaEditarAnotacao {
  .inputAnotacao {
    color: #353252;
    display: flex;
    justify-content: space-between;
    margin: 20px;
    border-radius: 4px;
  }
  .areaBtn {
    justify-content: space-between;
    margin: 20px;
  }
}
.buttonEditarAnotacao{
    cursor: pointer;
    margin-right: 1rem;
    z-index: 3;
    &:hover{
        color: var(--dommus-color-secondary);
    }
}.buttonExcluirAnotacao{
    cursor: pointer;
    z-index: 3;
    &:hover{
        color: #F44336;
    }
}