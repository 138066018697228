@font-face {
  font-family: Digital;
  src: url(../../../../assets/font/digital/DS-DIGI.TTF);
}

.voip {
  overflow-y: auto;
  height: 100%;
  .voip-control {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .voip-container {
      margin-top: -15px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 0px 16px;
      .mensagem-item {
        border-radius: 10px;
        color: #353252;
        padding: 10px;
        max-width: 80%;
        margin: 5px 0px;
      }
    }
    .timer {
      padding-bottom: 1rem;
      font-size: 40px;
      line-height: 1;
      display: flex;
      justify-content: center;
      font-family: Digital;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .fotoEnvolvido {
        padding-bottom: 1rem;
        font-size: 95px;
        line-height: 1;
        display: flex;
        justify-content: center;
      }
      .nomeEnvolvido {
        font-weight: bold;
      }
      .telefoneEnvolvido {
        font-size: 14px;
      }
    }
    .controls {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      button {
        &:focus {
          outline: 1px dotted;
        }
        border-radius: 50% !important;
        padding: 15px;
        margin-bottom: 1rem;
        &.botaoLigar:hover {
          color: #4caf50;
          background-color: white;
          border: 3px solid #4caf50;
        }
        &.botaoLigar {
          color: #eee;
          background-color: #4caf50;
          border: 3px solid #4caf50;
        }
        &.botaoDesligar:hover{
          color: #f44336;
          background-color: white;
          border: 3px solid #f44336;
        }
        &.botaoDesligar {
          color: #eee;
          background-color: #f44336;
          border: 3px solid #f44336;
        }
        &.botaoTransferir:hover{
          color:#f7941e;
          background-color: white;
          border: 3px solid #f7941e;
        }
        &.botaoTransferir {
          color: #eee;
          background-color: #f7941e;
          border: 3px solid #f7941e;
        }
        &.botaoMutar:hover {
          color: #353252;
          background-color: white;
          border: 3px solid #353252;
        }
        &.botaoMutar {
          color: #eee;
          background-color: #353252;
          border: 3px solid #353252;
        }
        svg {
          font-size: 1.4em;
        }
      }
    }
    .annotation {
      margin-top: 2em;
      textarea {
        width: 100%;
        padding: 4px;
        border-color: #eee;
        height: 7em;
      }
    }
  }

  .registros-container {
    margin-top: 2em;
    ul {
      list-style: none;
      li {
        background: #eee;
        padding: 5px 10px;
        margin: 3px 0;
      }
    }
  }
}
