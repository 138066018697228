.registro-atendimentos-section{
    
    padding: 15px;

    .items-rows{
        cursor: pointer;
    }

    .meio-comunicacao-indicador{
        padding: 0px 40%;
        .registro-col{
          border-radius: 0.2rem;
          height: 30px;
        }   
        .icon{
          padding-left: 0.1rem;
          padding-top: 0.4rem;          
        }
        .icon svg{
            transform: scale(1.5);
        }
    }
}

.hearder-modal-conteudo{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  .registro-col{
    border-radius: 0.2rem;
    height: 30px;
    width: 30px;
  }   
  .icon{
    padding-left: 0.55rem;
    padding-top: 0.4rem;          
  }
  .icon svg{
      transform: scale(1.5);
    }
}