$border: 1px #cecece solid;

.formCC {
  height: unset !important;
}
.formCCO {
  height: unset !important;
}

.btnEnviarEmail {
  width: -webkit-fill-available;
  background-color: var(--dommus-color-secondary);
  border:none;
  margin: 0 1rem;
}

.btnEnviarTemplate {
  width: -webkit-fill-available;
  border:none;
  margin: 0 1rem;
}

.templates-scrolaveis {
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.email-container {
  overflow-y: auto;
  height: 90%;
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem 0.5rem 0rem 0.5rem;
  margin: -1rem 0rem 0rem 0rem;
  .email {
    background-color: #ffffff;
    border: $border;
    border-radius: 4px;
    // padding: 5px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .header {
      border-bottom: $border;
      font-weight: 500;
      font-size: 0.9em;
      .dadosCopiaCortesia {
        .emailCopiaCortesia {
          color: #868aa8;
        }
      }
      .dadosEnvio {
        text-align: left;
        background-color: #ececec;
        padding: 0.5rem;
      }
      .data {
        font-size: 0.8em;
        text-align: right;
      }
      .dadosAssunto {
        text-align: left;
        .iconeEnviadoRecebido{
          font-size: 1rem;
          margin-right: 0.25rem;
        }
      }
      .subject {
        font-size: 1em;
        font-weight: 700;
        text-align: center;
        width: 100%;
        margin: 5px 0;
      }
      .header-body {
        display: flex;
        flex-direction: row;
        .from-to {
          flex-grow: 1;
        }
      }
    }
    .body {
      margin-top: 10px;
      padding-left: 1rem;
      ul{
        margin-left: 2rem;
      }
    }
  }

  .mail-attachments-section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 5px 15px;
    padding-bottom: 15px;
    
    .thumb-anexo{
      width: 8rem;
      height: 8rem;
      max-width: 8rem;
      max-height: 8rem;
    }

    .thumb-anexo .thumb.img-file{
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      max-width: 8rem;
      max-height: 8rem;
    }
    
    .thumb-anexo .thumb.pdf-file{
      display: flex;
      align-items: center;
      max-width: 8rem;
      max-height: 8rem;
    }

    .thumb-anexo .thumb.file-background{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.5rem;
      width: 8rem;
      height: 8rem;
    }

    
  }
}

.mail-modal-anexos{
  height: 80vh;

  .documento-viewer-container{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.modal-title{
  width: 100%;
}

.header-visualizar-arquivo{
  display: flex;
  flex-direction:  row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
}

.btn-download-file{
  font-size: 1rem;
  color: var(--dommus-color);
}

.footer {
  padding-top: 1rem;

}
