.title-disparo{
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title-disparo .badge-status{
    border-radius: 5px;
    padding: 1px 15px;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: capitalize;
}
.title-disparo .section-badges{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}
.title-disparo .section-badges .btn-imprimir-relatorio{
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 1px 8px;
    font-size: 1rem;
    cursor: pointer;
}

.title-disparo .badge-status.concluido{
    background-color: var(--dommus-color-green);
}

.title-disparo .badge-status.pendente{
    background-color: var(--dommus-color-2);
}

.title-disparo .badge-status.cancelado{
    background-color: var(--dommus-color-red);
}

.badge-status-log{
    margin: 8px 0px;
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 1px 15px;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: capitalize;
}

.badge-status-log.concluido{
    background-color: var(--dommus-color-green);
}

.badge-status-log.pendente{
    background-color: var(--dommus-color-2);
}

.badge-status-log.cancelado{
    background-color: var(--dommus-color-red);
}
.badge-status-log.erro{
    background-color: var(--dommus-color-dark);
}

.toggle-disparo-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.log-disparo-section{
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 10px;
}

.log-disparos-linhas-section{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    padding: 0px 10px;
}

.log-disparos-linhas-section .log-disparo-linha{
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
}

.data-disparo-item-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.section-status-disparo{
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 5px;
}

.badge-status-disparo{
    background-color: var(--dommus-color-cinza);
    border-radius: 8px;
    padding: 3px 8px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.badge-status-disparo.enviado{
    background-color: var(--dommus-color-green);
}
.badge-status-disparo.entregue{
    background-color: blue;
}
.badge-status-disparo.lido{
    background-color: orange;
    position: relative;
}
.badge-status-disparo.lido .qts-lido{
    background-color: var(--dommus-color-red);
    position: absolute;
    top: -8px;
    right: -3px;
    padding: 1px 4px;
    font-size: 0.6rem;
    border-radius: 50%;
    font-weight: 600;
}


.badge-status-disparo.anexo{
    background-color: brown;
}
.badge-status-disparo.link{
    background-color:  cornflowerblue;
}