.agendamento-disparo-item-section{
    position: relative;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 10px;
}

.checkbox-agendamento label,.checkbox-agendamento input[type=checkbox]{
    cursor: pointer;
}

.agendamento-disparo-item-section .ocultar-campo{
    display: none;
}

.btn-remover-agendamento{
    position: absolute;
    border: none;
    background-color: red;
    padding: 5px 10px;
    border-radius: 50%;
    right: -8px;
    top: -10px;
    z-index: 20;
}