.divImgBanner {
    width: 100%;
}

.modalImgPortal {
    max-width: 100%;
    width: 250px;
    height: 250px;
    object-fit: cover;
}

.modalImgPortalBanner {
    max-width: 100%;
    width: 100%;
    height: 140px;
    object-fit: cover;
}

.botoesEditarFoto {
    position:absolute;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

}

.checkbox-imagem-galeria .form-check-input {
    margin-left: 0.5rem!important;
}

.checkbox-imagem-galeria {
    background-color: var(--dommus-color);
    margin-bottom: 0px;
}

.checkbox-anexo {
    background-color: var(--dommus-color);
}

.cardArquivo {
    cursor: default;
    display: flex;
    justify-content: center;
    width: 15rem;
    margin: 10px;
    border: 0.25rem solid rgba(158, 155, 155, 0.2);
    display: flex;
    justify-content: end;
    padding: 0.5rem;
}

.cardArquivo .row{
  font-size: 1rem;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.checkbox-anexo .form-check {
    padding-left: 1.65rem;
}

.fixed-height-attachment{
  height: 20rem;
  overflow-y: auto;
}
