.mensagemImagem {
  display: -webkit-box;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  // width: fill-available;
  border-radius: 0.5rem;
}
.textoImagem {
  display: flex;
}
