.dadosPessoaisLead {
  .dadosIniciais {
    margin: 10px 0px 0px 20px;
  }
  .telefonecliente {
    font-size: 12px;
  }
  .emailcliente {
    font-size: 12px;
  }
}
.outrosDados {
  margin-left: 10px;
  margin-bottom: 10px;
}
