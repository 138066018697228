$cor-recebido: #b6b6b6;
$cor-enviado: var(--dommus-color-secondary);
$cor-texto: #ffff;


.chatbot-container {
    overflow-y: auto;
    height: 90%;
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem 0.5rem 0rem;
    margin: -1rem 0rem 0rem 0rem;
    .mensagem-item {
      border-radius: 10px;
      color: $cor-texto;
      padding: 10px;
      margin: 5px 0px;
      &.enviada {
        // display: flex;
        background-color: $cor-enviado;
        margin-left: auto;
        max-width: 50%;
        width: fit-content;
        // .data {
        //     text-align: right;
        // }
      }
      &.recebida {
        // display: flex;
        background-color: $cor-recebido;
        float: left;
        max-width: 50%;
        width: fit-content;
      }
    }
    .data {
      color: $cor-recebido;
      text-align: left;
      font-size: 0.6em;
      display: block;
      width: 98%;
  
      &.enviada {
        text-align: right;
        margin-left: -0.3rem;
      }
      &.recebida {
        margin-left: 0.3rem;
      }
    }
  }


.optionsChatBlip{
    display: flex;
    width: 100%;
    justify-content:center;
    align-items:center;
    padding: 0.5rem;
    background: var(--dommus-color-secondary-light);
    color: var(--white);
    border: 1px solid var(--dommus-color-secondary);
}