.thumb{
    border: 2px solid #666;
    border-radius: 5px;
    cursor: pointer;
}

.img-file {
    position: relative;
    transition: all 500ms;    
    max-width: 5rem;    
    object-fit: cover;
}

.img-file img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pdf-file {
    position: relative;
    border-radius: 5px;
    max-width: 5rem;
    max-height: 5rem;
    overflow: hidden;
    cursor: pointer;
}

.pdf-thumb{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 5rem;
    min-height: 5rem;
    width: 100%;
    height: 100%;
}

.layer-download {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
    transition: all 500ms;
    border-radius: 5px;
    cursor: pointer;
}

.pdf-file:hover .layer-download{
    display: flex;
}
.img-file:hover .layer-download {
    display: flex;
}

.pdf-file object {
    overflow: hidden;
}

.img-file:active {
    transform: scale(0.95);
}

.file-background {
    border-radius: 5px;
    padding: 20px 25px;
    font-size: 2rem;
    cursor: pointer;
    transition: all 500ms;
}

.file-background:active {
    transform: scale(0.95);
}
