.templatesContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;

  .cabecalhoTemplates {
    .tituloTemplares {
      font-size: 14px;
      font-weight: bolder;
    }
    .infoTemplates {
      font-size: 12px;
      color: var(--dommus-color-cinza);
    }
    word-break: break-word;
    .selecioneTemplares {
      font-size: 14px;
      font-weight: bolder;
      margin-bottom: 1rem;
    }
  }

  .selecaoTemplates {
    .templateCard input[type="radio"] {
      display: none;
    }

    label .templateCard {
      transition-duration: 0.2s;
      transform-origin: 50% 50%;
    }

    .templateCard {
      display: contents;

      .template {
        display: grid;
        margin: 0.8rem;
      }

      label {
        position: relative;
        cursor: pointer;
        user-select: none;
        margin-left: -1.5rem;
      }

      label::before {
        font-size: x-large;
        background-color: white;
        color: white;
        //   display: block;
        border-radius: 50%;
        position: absolute;
        top: -1.2rem;
        left: -0.8rem;
        text-align: center;
        line-height: 85%;
        transition-duration: 0.4s;
        transform: scale(0);
      }
    }
    .templateCorpo {
      background-color: var(--dommus-color-dark);
      color: white;
      border-radius: 0.25rem;
      padding: 0;
      font-size: 1rem;
      display: flex;
      margin: 0.5rem;
      word-break: break-word;
    }
    .templateCard :checked + label {
      border-color: #ddd;
    }

    .templateCard :checked + label::before {
      content: "✓";
      background-color: var(--dommus-color-green);
      transform: scale(1);
      z-index: 25;
    }

    .templateCard :checked + label .midiaCard {
      transform: scale(0.9);
      box-shadow: 0 0 5px var(--dommus-color-green);
      z-index: -1;
    }
  }

  .btnEnviaPrimeiraMensagem {
    color: white;
    border-color: var(--dommus-color-secondary);
    background-color: var(--dommus-color-secondary);
    margin: 2rem 0 0 -0.5rem;
  }
}
