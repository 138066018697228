$cor-recebido: #b6b6b6;
$cor-enviado: var(--dommus-color-secondary);
$cor-texto: #ffff;

.tituloTemplateSms {
  margin-left: 1rem;
  font-weight: bolder;
  font-size: 14px;
}
.smsLista {
  margin-right: 1rem;
  .dataSms {
    color: $cor-recebido;
    font-size: 0.8em;
    text-align: right;
  }
}
.sms-template {
  display: flex;
  height: 28%;
  overflow-y: auto;
  flex-direction: column;
  .templateCard input[type="radio"] {
    display: none;
  }

  label .templateCard {
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  .templateCard {
    display: contents;
    .template {
      display: grid;
      background-color: var(--dommus-color-dark);
      color: white;
      border-radius: 0.25rem;
      padding: 0.5rem 2rem;
      font-size: 1rem;
      margin: 0rem 0rem 1rem 0rem;
    }
    label {
      position: relative;
      cursor: pointer;
      user-select: none;
      margin-left: -1.5rem;
    }
    label::before {
      font-size: x-large;
      background-color: white;
      color: white;
      //   display: block;
      border-radius: 50%;
      position: absolute;
      top: -1.2rem;
      left: -0.8rem;
      text-align: center;
      line-height: 85%;
      transition-duration: 0.4s;
      transform: scale(0);
    }
  }
  .templateCard :checked + label {
    border-color: #ddd;
  }

  .templateCard :checked + label::before {
    content: "✓";
    background-color: var(--dommus-color-green);
    transform: scale(1);
    z-index: 25;
  }

  .templateCard :checked + label .midiaCard {
    transform: scale(0.9);
    box-shadow: 0 0 5px var(--dommus-color-green);
    z-index: -1;
  }
}

.sms-container {
  overflow-y: auto;
  height: 90%;
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem 0rem 0rem 0.5rem;
  margin: -1rem 0rem 0rem 0rem;

  .mensagem-item {
    border-radius: 10px;
    color: $cor-texto;
    padding: 10px;
    max-width: 80%;
    margin: 5px 0px;
    img {
      max-width: 10rem;
    }

    &.enviada {
      background-color: $cor-enviado;
      margin-left: auto;
      max-width: 50%;
      width: fit-content;
      // .data {
      //     text-align: right;
      // }
    }
    &.recebida {
      background-color: $cor-recebido;
      float: left;
      max-width: 50%;
      width: fit-content;
    }
  }
  .data {
    color: gray;
    text-align: left;
    font-size: 0.7em;
    display: block;
    width: 100%;

    &.enviada {
      text-align: right;
      margin-left: -0.3rem;
    }
    &.recebida {
      margin-left: 0.3rem;
    }
  }
}
.wrap-input-sms {
  width: 100%;
  display: flex;
  margin-top: 10px;
  background-color: #ebebeb;
  padding: 10px 5px;
  textarea {
    flex-grow: 1;
    overflow-y: auto;
    height: 50px;
    padding: 5px 20px;
    display: flex;
    border: 0;
    font-size: 1em;
  }

  button {
    background: none;
    padding: 5px 10px;
    color: var(--dommus-color-secondary);
    border: none;
    &:hover {
      background-color: #ebebeb;
    }
    &:focus {
      border: 0;
    }
  }
}
.footerEnviarMensagem {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}
