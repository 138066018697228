.dommus-menu{
    position: fixed;
    width: 100%;
    z-index: 8;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: var(--dommus-color);
    color: #fff;
}

.dommus-menu .logo{
    height: 75px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.dommus-menu .logo picture {
    display: flex;
    align-items: center;
    height: 100%;
}
.dommus-menu .online{
    margin-right: 20px;
}

.dommus-menu .logo img{
    width: 8rem;
    max-width: 100%;
    max-height: 100%;
}

.dommus-menu .right-nav {
    display: flex;
    height: 75px;
}

.dommus-menu .info-nav{
    font-size: 30px;
    height: 75px;
    align-items: center;
    display: inline-flex;
}
.dommus-menu .info-nav .picture-topo img{
    width: 25px;
}
.dommus-menu .info-nav .barsMenuMobile{
  display: none;
}

.dommus-menu .info-nav  .picture-topo{
    cursor: pointer;
    width: 30px;
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
}

.dommus-menu .right-nav .info-nav > div {
    margin-right: 20px;
    display: inline-flex;
}
.dommus-menu .right-nav .modulo-name {
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    line-height: unset;
    padding: 0;
    margin: 0;
    margin-right: 25px;
}
.dommus-menu .right-nav .user-name {
    color: #fff;
    font-size: 15px;
    line-height: unset;
    padding: 0;
    margin: 0px 5px;

}
.dommus-menu .online{
    display: flex;
    align-items: center;
}
.dommus-mobile-navigation{
    display: none;
}

.modulo-name{
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 700;
    color: #fff;
    font-size: 1.1rem;
    line-height: unset;
    text-transform: uppercase;
}

@media (max-width:767px) {
    .dommus-menu .info-nav  .picture-topo{
        margin-right: 13px;
    }
    .dommus-menu .right-nav{
        margin-left: 5px;
    }
    .dommus-menu{
        height: 55px;
    }
    .dommus-menu .logo img{
        max-width: 90%;
    }
    .dommus-menu .onlineFlag{
      display: none;
    }
    .dommus-menu .info-nav  .picture-topo .onlineFlag{
        display: none;
    }
    .dommus-menu .info-nav  .picture-topo .offlineFlag{
        display: none;
    }
    .dommus-mobile-navigation{
        display: block;
    }
    .dommus-menu .info-nav .barsMenuMobile{
      display: flex;
    }
    .dommus-mobile-navigation-wrapper a{
        color: var(--dommus-color) !important;
    }
    .dommus-mobile-navigation .navigation li.btn-open-navigation a svg {
        font-size: 4rem;
        margin-bottom: 1rem;
    }
}
.dommus-menu .perfil-dropdown {
    background: none;
    display: flex;
    border: none;
}
.dommus-menu .perfil-dropdown h1.user-name {
    margin-top: 10px;
}
.dommus-menu .perfil-dropdown::after {
    content: none;
}

.perfil-dropdown.btn-primary:not(:disabled):not(.disabled).active,
.perfil-dropdown.btn-primary:not(:disabled):not(.disabled):active,
.perfil-dropdown.show>.btn-primary.dropdown-toggle,
.perfil-dropdown.show>.btn-primary.dropdown-toggle:focus,
.perfil-dropdown.btn-primary.focus,
.perfil-dropdown.btn-primary:focus{
    background: none !important;
    border: none !important;
    box-shadow: none !important;
}
